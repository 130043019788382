<template>
    <div>
        <v-row class="mx-3 pt-5" no-gutters>
            <v-col cols="12" sm="6" lg="4">
                <v-text-field
                    class="ma-2"
                    readonly
                    label="Cita No."
                    v-model="estaConsultaDatos.citaId"
                    dense
                />
            </v-col>

            <v-col cols="12" sm="6" lg="8">
                <v-text-field
                    class="ma-2"
                    readonly
                    label="Paciente"
                    v-model="estaConsultaDatos.paciente"
                    dense
                />
            </v-col>

            <v-col cols="12" sm="6" lg="4">
                <v-text-field
                    class="ma-2"
                    readonly
                    label="Edad"
                    v-model="estaConsultaDatos.edad"
                    dense
                />
            </v-col>

            <v-col cols="12" sm="6" lg="4">
                <v-text-field
                    class="ma-2"
                    readonly
                    label="Fecha"
                    v-model="estaConsultaDatos.fecha"
                    dense
                />
            </v-col>

            <v-col cols="12" sm="6" lg="4">
                <v-text-field
                    class="ma-2"
                    readonly
                    label="Tipo"
                    v-model="esteTipo.tipo"
                    dense
                />
            </v-col>

            <v-col cols="12">
                <v-text-field
                    class="ma-2"
                    readonly
                    label="Tipo"
                    v-model="esteTipo.tipo"
                    dense
                />
            </v-col>
        </v-row>
        <div v-if="estaConsultaDatos">

            <v-tabs
                v-model="tab"
                grow
                show-arrows
            >
                <v-tab>
                    <!-- Tab 0 -->
                    Somatometría
                </v-tab>
                <v-tab>
                    <!-- Tab 1 -->
                    Diagnósticos
                </v-tab>
                <v-tab>
                    <!-- Tab 2 -->
                    Notas
                </v-tab>
                <v-tab>
                    <!-- Tab 3 -->
                    Historia clínica
                </v-tab>
                <v-tab>
                    <!-- Tab 4 -->
                    S.I.S.
                </v-tab>
                <v-tab>
                    <!-- Tab 5 -->
                    Laboratorio
                </v-tab>
                <v-tab>
                    <!-- Tab 6 -->
                    Cirugías
                </v-tab>
                <v-tab>
                    <!-- Tab 7 -->
                    Enfermería
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <!-- Tab 0 -->
                    <consulta-somato/>
                </v-tab-item>

                <v-tab-item>
                    <!-- Tab 1 -->
                    <consulta-diags/>
                </v-tab-item>

                <v-tab-item>
                    <!-- Tab 2 -->
                    <consulta-notas
                        :internamiento="estaConsultaDatos.internamiento"
                    />
                </v-tab-item>

                <v-tab-item>
                    <!-- Tab 3 -->
                    Cuatro
                </v-tab-item>

                <v-tab-item>
                    <!-- Tab 4 -->
                    Cinco
                </v-tab-item>

                <v-tab-item>
                    <!-- Tab 5 -->
                    Laboratorio
                </v-tab-item>

                <v-tab-item>
                    <!-- Tab 6 -->
                        <consulta-cirugias
                            :dp="{pacienteId:estaConsultaDatos.cip}"
                            :tab= "6"
                            :esteTab="tab"
                            :permiteNuevo="true"
                            :consulta="estaConsultaDatos.citaId"
                        />
                </v-tab-item>

                <v-tab-item>
                    <!-- Tab 7 -->
                    <consulta-enfermeria
                        :internamiento="estaConsultaDatos.internamiento"
                    />
                </v-tab-item>

            </v-tabs-items>
        </div>

    </div>
</template>

<script>
export default {
    props:{
        estaConsultaDatos:Object
    },
    components:{
        'consulta-somato':()=>import('@/components/consulta/attn/ConsultaAttnSomato'),
        'consulta-diags':()=>import('@/components/consulta/attn/ConsultaAttnDiags'),
        'consulta-notas':()=>import('@/components/hospital/Notas'),
        'consulta-enfermeria':()=>import('@/components/hospital/Enfermeria'),
        'consulta-cirugias':()=>import('@/components/pacientes/PacienteCirugias'),
    },
    data:()=>({
        tab:0,
    }),
    computed:{
        esteTipo(){
            let tipo = '?'
            let color="black"

            switch (this.estaConsultaDatos.tipo){
                case 1:
                    tipo='Primera vez'
                    color='orange'
                    break
                case 2:
                    tipo='Subsecuente'
                    color="green"
                    break
                case 3:
                    tipo='Especial'
                    color="blue"
                    break
            }
            return({tipo:tipo, color:color})
        },
    },
    watch:{
        estaConsultaDatos(val){
            console.log(val)
        },
    },

}
</script>

<style>

</style>